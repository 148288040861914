@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: var(--font-inter);
	--scroll-behavior: smooth !important;
	scroll-behavior: smooth !important;
}

@layer base {
	:root {
		/* Base Colors */
		--background: 240 67% 98%;
		--foreground: 228 23% 28%;

		/* UI Elements */
		--primary: 22.5 97% 53.9%;
		--primary-foreground: 210 40% 98%;
		--secondary: 222.9 23.2% 28.2%;
		--secondary-foreground: 210 40% 98%;
		--destructive: 346.8 77.2% 49.8%;
		--destructive-foreground: 0 0% 98%;
		--muted: 223 12% 67%;
		--muted-foreground: 215 20.2% 65.1%;
		--accent: 219.7 77.1% 80.6%;
		--accent-foreground: 228 23% 28%;
		--popover: 0 0% 100%;
		--popover-foreground: 228 23% 28%;
		--card: 242.3 43% 95%;
		--card-foreground: 228 23% 28%;

		/* Borders and Rings */
		--border: 0 0% 84.7%;
		--input: 0 0% 84.7%;
		--ring: 0 0% 5.1%;

		/* Radius value */
		--radius: 0.5rem;
	}

	/* Dark mode */
	.dark {
		/* Base Colors */
		--background: 223 23% 28%;
		--foreground: 240 67% 98%;

		/* UI Elements */
		--primary: 22.5 97% 53.9%;
		--primary-foreground: 210 40% 98%;
		--secondary: 262 76% 58%;
		--secondary-foreground: 210 40% 98%;
		--destructive: 346.8 77.2% 49.8%;
		--destructive-foreground: 0 85.7% 97.3%;
		--muted: 220 9% 46%;
		--muted-foreground: 215.4 16.3% 46.9%;
		--accent: 219.7 77.1% 80.6%;
		--accent-foreground: 240 67% 98%;
		--popover: 224 23% 23%;
		--popover-foreground: 240 67% 98%;
		--card: 224 23% 23%;
		--card-foreground: 240 67% 98%;

		/* Borders and Rings */
		--border: 220 9% 34%;
		--input: 220 9% 34%;
		--ring: 0 0% 11%;

		/* Radius value */
		--radius: 0.5rem;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}

/* Editor Styles */
.tiptap.ProseMirror {
	height: 100%;
	min-height: 5rem;
	padding: 1rem;
}

.ProseMirror:focus {
	outline: none;
}

.font-inter {
	font-family: var(--font-inter);
}

.font-lora {
	font-family: var(--font-lora);
}

/* Editor Styles */
.tiptap.ProseMirror {
	height: 100%;
	min-height: 5rem;
	padding: 1rem;

	color: #374058; /* Tailwind's gray-800 */
}

.dark .tiptap.ProseMirror {
	background-color: #374058; /* Tailwind's gray-800 */
	color: #d1d5db; /* Tailwind's gray-300 */
}

.ProseMirror:focus {
	outline: none;
}

/* Classes para aplicar fontes específicas */
.font-inter {
	font-family: var(--font-inter);
}

.font-lora {
	font-family: var(--font-lora);
}

/* Basic editor styles */
.tiptap {
	@apply outline-none;
}

.tiptap > * + * {
	@apply mt-4;
}

.tiptap ul,
.tiptap ol {
	@apply pl-6 my-5;
}

.tiptap ul li,
.tiptap ol li {
	@apply my-1;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
	@apply leading-tight mt-10 mb-6 font-bold;
}

.tiptap h1 {
	@apply text-4xl;
}

.tiptap h2 {
	@apply text-3xl;
}

.tiptap h3 {
	@apply text-2xl;
}

.tiptap h4 {
	@apply text-xl;
}

.tiptap h5,
.tiptap h6 {
	@apply text-lg;
}

.tiptap code {
	@apply bg-gray-100 dark:bg-red-500 text-gray-800 dark:text-gray-200 rounded px-1.5 py-0.5 text-sm font-mono;
}

.tiptap pre {
	@apply bg-gray-900 dark:bg-gray-800 text-white rounded-lg p-4 my-6 font-mono text-sm overflow-x-auto;
}

.tiptap pre code {
	@apply bg-transparent text-inherit p-0;
}

.tiptap blockquote {
	@apply border-l-4 border-gray-200 dark:border-red-500 pl-4 my-6 italic text-red-500 dark:text-gray-300;
}

.tiptap hr {
	@apply border-t border-gray-200 dark:border-red-500 my-8;
}

/* Disabled button styles */
button:disabled {
	@apply opacity-50 cursor-not-allowed;
}
